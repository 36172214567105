'use client'

import { useCookiesBanner } from 'cookies/hooks/useCookiesBanner/useCookiesBanner'

import styles from '../LegalInfo.module.scss'

export function CookiesButton({
	cookiesBanner,
}: {
	cookiesBanner: { key: string }
}) {
	const { showCookiesConfiguration } = useCookiesBanner()

	return (
		<button className={styles.legalButton} onClick={showCookiesConfiguration}>
			{cookiesBanner.key}
		</button>
	)
}
